import React from 'react';
import { Location as ServiceLocation } from '../../../../utils/mappers/service.mapper';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { LocationDataHooks } from './dataHooks';
import { TFunction } from '../../controller';
import Text, { TextType } from '../../Text/Text';
import { LocationType } from '@wix/ambassador-availability-calendar/types';

export interface LocationProps {
  location: ServiceLocation;
}
const Location: React.FC<LocationProps> = ({ location }) => {
  const { t } = useTranslation();
  const locationText = getLocationText(location, t);

  return (
    <Text
      type={TextType.Secondary}
      data-hook={LocationDataHooks.LOCATION}
      tagName={AccessibilityHtmlTags.Paragraph}
    >
      {locationText}
    </Text>
  );
};

const getLocationText = (location: ServiceLocation, t: TFunction): string => {
  switch (location?.locationType) {
    case LocationType.OWNER_BUSINESS:
      return location?.name!;
    case LocationType.OWNER_CUSTOM:
      return location?.address!;
    case LocationType.CUSTOM:
      return t('app.booking-details.locations.client-place.text');
    default:
      return '';
  }
};

export default Location;
