import React from 'react';
import {
  TFunction,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { DateAndTimeDataHooks } from './dataHooks';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import Text, { TextType } from '../../Text/Text';

export interface DateAndTimeProps {
  dateRegionalSettingsLocale: string;
  startDate: string;
  totalNumberOfSessions: number;
}
const DateAndTime: React.FC<DateAndTimeProps> = ({
  totalNumberOfSessions,
  startDate,
  dateRegionalSettingsLocale,
}) => {
  const { t } = useTranslation();
  const { isEditor } = useEnvironment();
  const dateAndTime = isEditor
    ? t('app.dummy-data.date-and-time')
    : getDateAndTime(
        startDate,
        dateRegionalSettingsLocale,
        totalNumberOfSessions,
        t,
      );

  return (
    <Text
      type={TextType.Primary}
      data-hook={DateAndTimeDataHooks.SLOT_DATE_AND_TIME}
      tagName={AccessibilityHtmlTags.Paragraph}
    >
      {dateAndTime}
    </Text>
  );
};

const getDateAndTime = (
  startDate: string,
  dateRegionalSettingsLocale: string,
  totalNumberOfSessions: number,
  t: TFunction,
) => {
  const locale = dateRegionalSettingsLocale!;
  const dateAndTimeFormatter = new DateTimeFormatter(locale);
  const dateAndTimeFormat = dateAndTimeFormatter.formatDateAndTime(startDate);
  const isServiceHasMultipleSessions = totalNumberOfSessions > 1;

  return isServiceHasMultipleSessions
    ? t('app.booking-details.course.date-and-time.starts.text', {
        date: dateAndTimeFormat,
      })
    : dateAndTimeFormat;
};

export default DateAndTime;
