import React from 'react';
import { Divider } from 'wix-ui-tpa/Divider';
import { classes } from './Header.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { Header as FormHeader } from '@wix/ambassador-services-catalog-server/http';
import { HeaderDataHooks } from './dataHooks';
import Text, { TextType } from '../../Text/Text';

export const Header: React.FC<FormHeader> = ({
  title,
  description,
  isDescriptionHidden,
}) => {
  const showDescription = !isDescriptionHidden;

  return (
    <div className={classes.root}>
      <Text
        type={TextType.Title}
        className={classes.title}
        data-hook={HeaderDataHooks.TITLE}
        tagName={AccessibilityHtmlTags.MainHeading}
      >
        {title}
      </Text>
      <Divider
        className={classes.divider}
        data-hook={HeaderDataHooks.DIVIDER}
      />
      {showDescription ? (
        <Text
          type={TextType.Primary}
          className={classes.description}
          data-hook={HeaderDataHooks.DESCRIPTION}
          tagName={AccessibilityHtmlTags.SecondaryHeading}
        >
          {description}
        </Text>
      ) : null}
    </div>
  );
};
