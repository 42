import React from 'react';
import { useFormActions } from '../../../Hooks/useFormActions';
import { classes } from './PaymentOptions.st.css';
import {
  RadioButton,
  RadioButtonTheme,
} from 'wix-ui-tpa/dist/src/components/RadioButton/RadioButton';
import { RadioButtonGroup } from 'wix-ui-tpa/RadioButtonGroup';
import { PaymentOptionsDataHooks } from './dataHooks';
import { PaymentVariationType } from '../../../../../types/types';
import Text, { TextType } from '../../../Text/Text';

export type PaymentOption = {
  id?: string;
  checked?: boolean;
  label?: string;
  paymentType?: PaymentVariationType;
  suffix?: string;
  validUntil?: string;
  disabled?: boolean;
};
export interface PaymentOptionsProps {
  paymentOptions: PaymentOption[];
}

const PaymentOptions: React.FC<PaymentOptionsProps> = ({ paymentOptions }) => {
  const { setPaymentOptionAction } = useFormActions();

  return (
    <div className={classes.root}>
      {paymentOptions ? (
        <RadioButtonGroup
          name="paymentOptions"
          withSpacing
          theme={RadioButtonTheme.Box}
          onChange={(paymentOption: string) =>
            setPaymentOptionAction(paymentOption)
          }
          className={classes.paymentOptions}
          data-hook={PaymentOptionsDataHooks.PAYMENT_OPTIONS}
          value={paymentOptions?.find((option) => option.checked)?.id}
        >
          {paymentOptions?.map((paymentOption, index) => {
            return (
              <RadioButton
                data-hook={`${PaymentOptionsDataHooks.PAYMENT_OPTION}-${index}`}
                key={paymentOption.id}
                value={paymentOption.id!}
                children={paymentOptionLabel(paymentOption)}
                disabled={paymentOption.disabled}
              />
            );
          })}
        </RadioButtonGroup>
      ) : null}
    </div>
  );
};

const paymentOptionLabel = (paymentOption: PaymentOption) => {
  return (
    <div
      className={classes.paymentOptionLabel}
      data-hook={PaymentOptionsDataHooks.PAYMENT_OPTION}
    >
      <div>
        <Text type={TextType.Primary}>{paymentOption.label}</Text>
        {paymentOption.validUntil ? (
          <div>
            <Text type={TextType.Secondary}>{paymentOption.validUntil}</Text>
          </div>
        ) : null}
      </div>
      <div>
        <Text type={TextType.Secondary}>{paymentOption.suffix}</Text>
      </div>
    </div>
  );
};

export default PaymentOptions;
