import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Divider } from 'wix-ui-tpa/Divider';
import { DetailsDataHooks } from './dataHooks';
import { classes, st } from './Details.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { Service } from '../../../../utils/mappers/service.mapper';
import VideoConferenceBadge from '../VideoConferenceBadge/VideoConferenceBadge';
import Duration from '../Duration/Duration';
import StaffMembers from '../StaffMembers/StaffMembers';
import Location from '../Location/Location';
import DateAndTime from '../DateAndTime/DateAndTime';
import Text, { TextType } from '../../Text/Text';

export interface DetailsProps {
  service: Service;
  startDate: string;
  endDate: string;
  dateRegionalSettingsLocale: string;
}

const Details: React.FC<DetailsProps> = ({
  service,
  startDate,
  endDate,
  dateRegionalSettingsLocale,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  return (
    <div className={st(classes.root, { isMobile })}>
      <Text
        type={TextType.Title}
        data-hook={DetailsDataHooks.HEADER_TITLE}
        className={classes.title}
        tagName={AccessibilityHtmlTags.SecondaryHeading}
      >
        {t('app.booking-details.title')}
      </Text>
      <Divider
        className={classes.divider}
        data-hook={DetailsDataHooks.DIVIDER}
      />
      <VideoConferenceBadge
        videoConferenceProviderId={service.videoConferenceProviderId}
      />
      <Text
        type={TextType.Primary}
        data-hook={DetailsDataHooks.SERVICE_NAME}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {service.name}
      </Text>
      <DateAndTime
        totalNumberOfSessions={service.totalNumberOfSessions}
        startDate={startDate}
        dateRegionalSettingsLocale={dateRegionalSettingsLocale}
      />
      <Duration
        startDate={startDate!}
        endDate={endDate!}
        totalNumberOfSessions={service.totalNumberOfSessions}
      />
      <StaffMembers staffMembers={service.staffMembers} />
      <Location location={service.location} />
    </div>
  );
};

export default Details;
