import React from 'react';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { LoginDataHooks } from './dataHooks';
import { classes, st } from './Login.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { useFormActions } from '../../Hooks/useFormActions';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import Text, { TextType } from '../../Text/Text';

export const Login: React.FC = () => {
  const { onLogin } = useFormActions();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  return (
    <div
      data-hook={LoginDataHooks.LOGIN_COMPONENT}
      className={st(classes.root, { isMobile })}
    >
      <Text
        type={TextType.Secondary}
        className={classes.text}
        data-hook={LoginDataHooks.LOGIN_TEXT}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {t('app.login.link.prefix.text')}{' '}
        <TextButton
          data-hook={LoginDataHooks.LOGIN_BUTTON}
          className={classes.link}
          onClick={() => onLogin()}
        >
          {t('app.login.link.text')}
        </TextButton>{' '}
        {t('app.login.link.suffix.text')}
      </Text>
    </div>
  );
};
