import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import React, { useState } from 'react';
import { classes, st } from './PromoCode.st.css';
import { TextButton, TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { PromoCodeDataHooks } from './dataHooks';
import { useFormActions } from '../../../Hooks/useFormActions';
import { ReactComponent as PromoTag } from '../../../../../assets/icons/PromoTag.svg';
import { TextField } from 'wix-ui-tpa/TextField';
import { Button, PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { Error } from 'wix-ui-tpa/dist/src/assets/icons';
import { FormErrors } from '../../../../../utils/state/initialStateFactory';
import { CouponsErrorType } from '../../../../../types/types';

export const ENTER = 'enter';
export interface PromoCodeProps {
  couponDiscount: number;
  errors: FormErrors[];
  showPromoCodeInput: boolean;
}

const PromoCode: React.FC<PromoCodeProps> = ({
  couponDiscount,
  errors,
  showPromoCodeInput,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { setCoupon, removeCoupon, onTogglePromoCode } = useFormActions();
  const [couponCode, setCouponCode] = useState('');

  const clearCoupon = () => {
    setCouponCode('');
    removeCoupon();
  };

  const hasCouponError = () => {
    return errors.some((error) =>
      Object.keys(CouponsErrorType).includes(error),
    );
  };

  const hasError = hasCouponError();
  const alreadyUsedCoupon = errors.some(
    (error) => error === CouponsErrorType.ERROR_COUPON_USAGE_EXCEEDED,
  );

  return (
    <div className={st(classes.root, { isMobile })}>
      <TextButton
        priority={TEXT_BUTTON_PRIORITY.link}
        className={classes.promoCodeTextButton}
        data-hook={PromoCodeDataHooks.PROMO_CODE_TEXT_BUTTON}
        onClick={() => onTogglePromoCode()}
        prefixIcon={<PromoTag />}
      >
        {t('app.payment.coupon.add-a-promo-code.text')}
      </TextButton>
      {showPromoCodeInput ? (
        <>
          {alreadyUsedCoupon ? (
            <AlreadyUserError couponCode={couponCode} />
          ) : null}
          <TextField
            data-hook={PromoCodeDataHooks.PROMO_CODE_INPUT}
            success={!!couponDiscount && !hasError}
            successIcon={!!couponDiscount && !hasError}
            value={couponCode}
            error={hasError}
            errorMessage={
              hasError
                ? t('app.payment.promo-code.errors.invalid-coupon-code')
                : ''
            }
            onChange={(e) => setCouponCode(e.currentTarget.value)}
            onKeyDown={(e) =>
              e.key.toLowerCase() === ENTER
                ? setCoupon(e.currentTarget.value)
                : null
            }
          />
          <Button
            size={SIZE.medium}
            priority={
              couponDiscount && !hasError
                ? PRIORITY.basicSecondary
                : PRIORITY.basic
            }
            className={`${classes.couponCta} ${
              couponDiscount && !hasError
                ? classes.validCTAButton
                : classes.invalidCTAButton
            }`}
            data-hook={PromoCodeDataHooks.ACTION_BUTTON}
            upgrade
            onClick={() =>
              couponDiscount ? clearCoupon() : setCoupon(couponCode)
            }
          >
            {couponDiscount && !hasError
              ? t('app.payment.promo-code.remove.text')
              : t('app.payment.promo-code.apply.text')}
          </Button>
        </>
      ) : null}
    </div>
  );
};

const AlreadyUserError: React.FC<any> = ({
  couponCode,
}: {
  couponCode: string;
}) => {
  const { t } = useTranslation();
  return (
    <SectionNotification
      type="error"
      className={classes.alreadyUsedCoupon}
      data-hook={PromoCodeDataHooks.ALREADY_USED_COUPON}
    >
      <SectionNotification.Icon icon={<Error />} />
      <SectionNotification.Text>
        {t('app.payment.promo-code.errors.already-used-coupon-code', {
          couponCode,
        })}
      </SectionNotification.Text>
    </SectionNotification>
  );
};

export default PromoCode;
